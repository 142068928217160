










































































































/* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from '@vue/composition-api'
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations, mapGetters } from 'vuex'

  export default defineComponent({
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h: any) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover } : { hover: any }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },
    created () {
      this.$vuetify.breakpoint.smAndDown ? this.setDrawer(false) : this.setDrawer(true)
    },
    props: {
      value: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      profile: [
        // { title: 'Profile' },
        // { title: 'Settings' },
        // { divider: true },
        { title: 'login.logout', route: '/logout' },
      ],
    }),

    computed: {
      ...mapState('theme', ['drawer']),
      ...mapGetters('user', {
        isUser: 'isUser',
      }),
    },
    methods: {
      ...mapMutations('theme', {
        setDrawer: 'setDrawer',
      }),
    },
  })
